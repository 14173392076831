<template>
  <ol class="mt-6 space-y-6">
    <li
      :key="'li-experience-' + key"
      v-for="(experience, key) in experiences"
      class="pb-6 cursor-pointer"
    >
      <a :href="experience.webUrl" class="flex gap-4" target="_blank">
        <div
          class="relative mt-1 flex h-10 w-10 flex-none items-center justify-center rounded-full shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0"
        >
          <img
            alt=""
            loading="lazy"
            width="32"
            height="32"
            decoding="async"
            class="h-7 w-7 rounded-full"
            style="color: transparent"
            :src="'/img/experiences/' + experience.logo"
          />
        </div>
        <div class="md:flex md:justify-between w-full">
          <div class="flex flex-auto flex-wrap gap-x-2 mb-2 w-1/2">
            <div class="w-full flex-none text-sm font-medium text-zinc-900 dark:text-zinc-100">
              {{ experience.position }}
            </div>
            <div class="text-sm text-zinc-500 dark:text-zinc-400">
              {{ experience.company }}
            </div>
            <div class="w-full flex-none text-xs font-medium text-zinc-500 dark:text-zinc-400">
              {{ experience.location }}
            </div>
          </div>
          <div class="w-1/2 md:w-auto">
            <div class="flex text-xs text-zinc-900 dark:text-zinc-100 w-full md:text-right">
              <div class="capitalize">
                {{ dayjsFormat(experience.startDate, 'MMM YYYY') }}
              </div>
              &nbsp — &nbsp
              <div v-if="experience.currently" class="text-teal-500 font-bold">
                {{ t('experiences.present') }}
              </div>
              <div v-else-if="experience.endDate" class="capitalize">
                {{ dayjsFormat(experience.endDate, 'MMM YYYY') }}
              </div>
            </div>
            <div
              v-if="!props.isMinimal"
              class="text-xs text-zinc-500 dark:text-zinc-400 md:text-right"
            >
              {{
                differenceBetweenTwoDates(
                  experience.startDate,
                  experience.endDate ? experience.endDate : new Date().toISOString().split('T')[0]
                )
              }}
            </div>
          </div>
        </div>
      </a>
      <div v-if="!props.isMinimal" class="text-xs pl-14 mt-2 text-zinc-600 dark:text-zinc-400">
        {{ experience.description }}
      </div>
    </li>
  </ol>
</template>

<script setup lang="ts">
import useDayjsComposable from '@/composables/useDayjsComposable'
const { dayjsFormat, differenceBetweenTwoDates } = useDayjsComposable()

import useExperiencesStore from '@/stores/experiences'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const experiencesStore = useExperiencesStore()

const experiences = experiencesStore.getExperiences

const props = defineProps({
  isMinimal: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped></style>
