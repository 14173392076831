export default [
  {
    path: '/posts',
    children: [
      {
        path: '',
        name: 'posts',
        component: () => import('../../views/PostsView.vue')
      },
      {
        path: 'ratio-padding-calculator',
        name: 'posts-ratio-padding-calculator',
        component: () => import('../../views/posts/RatioPaddingCalculatorView.vue')
      },
      {
        path: 'share-image',
        name: 'posts-share-image',
        component: () => import('../../views/posts/ShareImageView.vue')
      },
      {
        path: 'test',
        name: 'posts-test',
        component: () => import('../../views/posts/TestView.vue')
      }
    ]
  }
]
