<template>
  <time
    :datetime="props.date"
    class="order-first flex items-center text-base text-zinc-400 dark:text-zinc-500"
  >
    <span class="h-4 w-0.5 rounded-full bg-zinc-200 dark:bg-zinc-500"></span>
    <div class="ml-3 capitalize">{{ dayjsFormat(props.date, 'MMMM D, YYYY') }}</div>
  </time>
</template>

<script setup lang="ts">
import useDayjsComposable from '@/composables/useDayjsComposable'
const { dayjsFormat } = useDayjsComposable()
const props = defineProps<{ date: string }>()
</script>
