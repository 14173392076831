<template>
  <RouterLink :to="`/posts/${props.post.slug}`">
    <article class="md:grid md:grid-cols-4 md:items-baseline space-y-10 my-10">
      <div class="md:col-span-3 group relative flex flex-col items-start">
        <h2 class="text-base font-semibold tracking-tight text-zinc-820 dark:text-zinc-100">
          <span class="relative z-10">{{ post.title }}</span>
        </h2>

        <DateTimeComponent
          class="relative z-10 order-first mb-3 flex items-center text-sm text-zinc-700"
          :date="props.post.date"
        />

        <p class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
          {{ post.description }}
        </p>
        <div
          aria-hidden="true"
          class="relative z-10 mt-4 flex items-center text-sm font-medium text-teal-500"
        >
          {{ t('posts.read_post')
          }}<svg
            viewBox="0 0 16 16"
            fill="none"
            aria-hidden="true"
            class="ml-1 h-4 w-4 stroke-current"
          >
            <path
              d="M6.75 5.75 9.25 8l-2.5 2.25"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>
      </div>
    </article>
  </RouterLink>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import DateTimeComponent from '@/components/common/DateTimeComponent.vue'
const { t } = useI18n()

const props = defineProps({
  post: { type: Object, required: true }
})
</script>
