import { ref } from 'vue'

export default function useImageAsBase64({ url }: { url: string }) {
  const base64 = ref<string>('')
  const status = ref('loading')

  const load = async () => {
    try {
      base64.value = (await toDataURL(url)).toString()
      status.value = 'success'
    } catch (e) {
      status.value = 'error'
      console.error(e)
    }
  }

  return {
    base64,
    status,
    load
  }
}

function toDataURL(url: string): Promise<string | ArrayBuffer> {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            if (reader.result === null) {
              reject(new Error('Reader without results'))
            }

            resolve(reader.result as string | ArrayBuffer)
          }
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
    )
}
